import XRegExp from 'xregexp';

export default {
  NAME: (value: string) => /^[a-z ,.'-]+$/i.test(value),
  CODE: (value: string) => /^[a-zA-Z0-9.]*$/.test(value),
  NOT_EMPTY: (value: any) => !['', undefined, null].includes(value),
  EMAIL: (value: string) =>
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i.test(
      value,
    ),
  PHONE: (value: string) =>
    /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/i.test(value),
  PASSWORD: {
    CONTAIN: {
      UPPERCASE_CHARACTERS: (value: string) => /[A-Z]+/.test(value),
      LOWERCASE_CHARACTERS: (value: string) => /[a-z]+/.test(value),
      DIGITS: (value: string) => /\d+/.test(value),
    },
    HAS: {
      VALID_CHARACTERS: (value: string) => /^[a-zA-Z\d_\-!@#$%()*&]+$/.test(value),
      LATIN_CHARACTERS: (value: string) =>
        Array.from(value.matchAll(XRegExp('\\p{Letter}+', 'ig'))).every((letters) =>
          XRegExp('^\\p{Latin}+$').test(letters[0]),
        ),
      LENGTH: (value: string) => /^.{4,50}$/.test(value),
    },
  },
  GLOBAL: {
    HAS: {
      LENGTH: (value: string, { min = 0, max = Number.MAX_SAFE_INTEGER }) =>
        new RegExp(`^.{${min},${max}$`).test(value),
      VALID_CHARACTERS: (
        value: string,
        {
          required = true,
          latinChars = false,
          digits = false,
          spaces = false,
          specialSymbols = '',
        },
      ) =>
        new RegExp(
          `^[${latinChars ? 'a-zA-Z' : ''}${digits ? '0-9' : ''}${
            spaces ? ' ' : ''
          }${specialSymbols}]${required ? '+' : '*'}$`,
        ).test(value),
    },
  },
};
