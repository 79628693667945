const WINDOW_BREAKPOINTS = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400,
} as const

export default WINDOW_BREAKPOINTS
export type Breakpoint = typeof WINDOW_BREAKPOINTS[keyof typeof WINDOW_BREAKPOINTS]
