import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactElement, useCallback, useState } from 'react';
import api from '../../../api';
import {
  lastNameValidator,
  fistNameValidator,
  emailValidator,
  invitationCodeValidator,
} from '../../../services/dataValidator';
import {
  State,
  FormValues,
  RegistrationValidators,
  RegistrationValidatorKey,
  ValidationErrors,
} from './types';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { toastGenerator } from '../../../utils/toast';
import { ROUTE_PATHS } from '../../../CONSTANTS/paths';
import useAuthStyles from '../../../styles/authStyles';

const validators: RegistrationValidators = {
  email: emailValidator,
  firstName: fistNameValidator,
  lastName: lastNameValidator,
  invitationCode: invitationCodeValidator,
};

const theme = createTheme({
  palette: {
    primary: {
      light: '#737ae0',
      main: '#7047ef',
      dark: '#5238c9',
      contrastText: '#fff',
    },
  },
});

const RegistrationPage: React.FC = (): ReactElement => {
  const [isRegistered, setIsRegistered] = useState(false);
  const [state, setState] = useState<State>({
    formValues: {
      firstName: '',
      lastName: '',
      invitationCode: '',
      email: '',
    },
    focused: null,
    errors: [],
  });

  const classes = useAuthStyles();

  const handleChange = useCallback(
    (prop: keyof FormValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setState((prevState) => ({
        ...prevState,
        formValues: { ...prevState.formValues, [prop]: event.target.value },
      }));
    },
    [],
  );

  const handleFocus = useCallback(
    (prop: keyof FormValues) => () => {
      setState((prevState) => ({
        ...prevState,
        focused: prop,
        errors: prevState.errors.filter((error) => error.key !== prop),
      }));
    },
    [],
  );

  const handleBlur = useCallback(
    (prop: keyof FormValues) => () => {
      setState((prevState) => ({
        ...prevState,
        focused: prop === prevState.focused ? null : prevState.focused,
      }));
    },
    [],
  );

  const hasError = useCallback(
    (key: keyof FormValues) => !!state.errors.find((error) => error.key === key),
    [state.errors],
  );

  const getHelperText = useCallback(
    (key: keyof FormValues) => state.errors.find((error) => error.key === key)?.message ?? ' ',
    [state.errors],
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validationResponses = await Promise.allSettled(
      Object.keys(validators).map((key) =>
        validators[key as RegistrationValidatorKey].validate(
          state.formValues[key as keyof FormValues],
        ),
      ),
    );

    const errors = validationResponses.reduce((acc: ValidationErrors[], response, index) => {
      if (response.status === 'rejected') {
        acc.push({
          key: Object.keys(validators)[index] as RegistrationValidatorKey,
          message: response.reason[0].message,
        });
      }
      return acc;
    }, []);

    if (errors.length > 0) {
      setState({
        ...state,
        errors,
      });
      return;
    }
    await api.auth.createAccount({
      email: state.formValues.email,
      firstName: state.formValues.firstName,
      lastName: state.formValues.lastName,
      inviteCode: state.formValues.invitationCode,
    });
    setIsRegistered(true);
    toastGenerator('You are registered!');
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        component="main">
        <Container maxWidth="xs" className={classes.auth}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pb: 6,
            }}>
            <Typography component="h1" variant="h5" sx={{ mt: 4 }}>
              Registration
            </Typography>
            {isRegistered ? (
              <Typography component="h1" variant="h5" sx={{ mt: 4 }}>
                You were registered succesfully, please activate your account by clicking on link
                that we sent on your mail!
              </Typography>
            ) : (
              <>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1, textAlign: 'center' }}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 2, mb: 0 }}
                    error={hasError('email')}>
                    <InputLabel htmlFor="email">Email Address</InputLabel>
                    <OutlinedInput
                      id="email"
                      name="email"
                      type="text"
                      value={state.formValues.email}
                      onChange={handleChange('email')}
                      onFocus={handleFocus('email')}
                      onBlur={handleBlur('email')}
                      label="Email Address"
                      autoFocus
                    />
                    <FormHelperText error>{getHelperText('email')}</FormHelperText>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 2, mb: 0 }}
                    error={hasError('firstName')}>
                    <InputLabel htmlFor="firstName">First Name</InputLabel>
                    <OutlinedInput
                      id="firstName"
                      name="firstName"
                      type={'text'}
                      value={state.formValues.firstName}
                      onChange={handleChange('firstName')}
                      onFocus={handleFocus('firstName')}
                      onBlur={handleBlur('firstName')}
                      label="First Name"
                    />
                    <FormHelperText error>{getHelperText('firstName')}</FormHelperText>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 2, mb: 0 }}
                    error={hasError('lastName')}>
                    <InputLabel htmlFor="lastName">Last Name</InputLabel>
                    <OutlinedInput
                      id="lastName"
                      name="lastName"
                      type={'text'}
                      value={state.formValues.lastName}
                      onChange={handleChange('lastName')}
                      onFocus={handleFocus('lastName')}
                      onBlur={handleBlur('lastName')}
                      label="Last Name"
                    />
                    <FormHelperText error>{getHelperText('lastName')}</FormHelperText>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 2, mb: 0 }}
                    error={hasError('invitationCode')}>
                    <InputLabel htmlFor="invitationCode">Invitation Code (optional)</InputLabel>
                    <OutlinedInput
                      id="invitationCode"
                      name="invitationCode"
                      type={'text'}
                      value={state.formValues.invitationCode}
                      onChange={handleChange('invitationCode')}
                      onFocus={handleFocus('invitationCode')}
                      onBlur={handleBlur('invitationCode')}
                      label="Invitation Code (optional)"
                    />
                    <FormHelperText error>{getHelperText('invitationCode')}</FormHelperText>
                  </FormControl>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, mb: 2 }}>
                    Register
                  </Button>
                </Box>
                <Link to={ROUTE_PATHS.SIGN_IN}>Already have an account?</Link>
              </>
            )}
          </Box>
        </Container>
      </Grid>
    </ThemeProvider>
  );
};

export default RegistrationPage;
