import { useMediaQuery } from 'react-responsive'
import WINDOW_BREAKPOINTS from '../CONSTANTS/WINDOW_BREAKPOINTS'

// eslint-disable-next-line react-hooks/rules-of-hooks
export const isSM = () => useMediaQuery({
  maxWidth: WINDOW_BREAKPOINTS.SM - 1,
})

// eslint-disable-next-line react-hooks/rules-of-hooks
export const isMD = () => useMediaQuery({
  minWidth: WINDOW_BREAKPOINTS.SM,
  maxWidth: WINDOW_BREAKPOINTS.MD - 1,
})

// eslint-disable-next-line react-hooks/rules-of-hooks
export const isLG = () => useMediaQuery({
  minWidth: WINDOW_BREAKPOINTS.MD,
  maxWidth: WINDOW_BREAKPOINTS.LG - 1,
})

// eslint-disable-next-line react-hooks/rules-of-hooks
export const isXL = () => useMediaQuery({
  minWidth: WINDOW_BREAKPOINTS.LG,
  maxWidth: WINDOW_BREAKPOINTS.XL - 1,
})

// eslint-disable-next-line react-hooks/rules-of-hooks
export const isXXL = () => useMediaQuery({
  minWidth: WINDOW_BREAKPOINTS.XL,
  maxWidth: WINDOW_BREAKPOINTS.XXL - 1,
})

// eslint-disable-next-line react-hooks/rules-of-hooks
export const is3XL = () => useMediaQuery({
  minWidth: WINDOW_BREAKPOINTS.XXL,
})

type Breakpoints = {
  minWidth?: number,
  maxWidth?: number,
}

export const betweenBreakpoints = ({
  minWidth,
  maxWidth,
// eslint-disable-next-line react-hooks/rules-of-hooks
}: Breakpoints) => useMediaQuery({
  minWidth,
  maxWidth,
})
