import rest from '../../api';
import {
  SignInProps,
  SignInResponseData,
  RefreshProps,
  RefreshResponseData,
  ActivateAccountProps,
  CreateAccountProps,
  CreateAccountResponseData,
  ActivateAccountResponseData,
  ResetPasswordProps,
  SendResetCodeProps,
} from './types';
import { ApiResponse } from '../../types';
import { PATHS } from '../../../CONSTANTS/paths';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  signIn: async (props: SignInProps): Promise<ApiResponse<SignInResponseData>> =>
    rest.post(PATHS.SIGN_IN, {
      email: props.email,
      password: props.password,
    }),
  createAccount: async (
    props: CreateAccountProps,
  ): Promise<ApiResponse<CreateAccountResponseData>> =>
    rest.post('', {
      email: props.email,
      lastName: props.lastName,
      firstName: props.firstName,
      inviteCode: props.inviteCode,
    }),
  activateAccount: async (
    props: ActivateAccountProps,
  ): Promise<ApiResponse<ActivateAccountResponseData>> =>
    rest.post(PATHS.EMAIL_ACTIVATE, {
      code: props.code,
    }),
  refresh: async (props: RefreshProps): Promise<ApiResponse<RefreshResponseData>> =>
    rest.post(PATHS.SIGNIN_REFRESH, {
      token: props.token,
    }),
  resetPassword: async (props: ResetPasswordProps): Promise<ApiResponse<any>> =>
    rest.put(PATHS.PASSWORD_RESET, {
      code: props.code,
      password: props.password,
      passwordRetype: props.passwordRetype,
    }),
  sendResetCode: async (props: SendResetCodeProps): Promise<ApiResponse<any>> =>
    rest.post(PATHS.PASSWORD_RESET, {
      email: props.email,
    }),
};
