import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactElement, useCallback, useState } from 'react';
import api from '../../../api';
import { passwordValidator, emailValidator } from '../../../services/dataValidator';
import { State, FormValues, LoginValidators, LoginValidatorKey, ValidationErrors } from './types';
import WINDOW_BREAKPOINTS from '../../../CONSTANTS/WINDOW_BREAKPOINTS';
import { betweenBreakpoints } from '../../../utils/mediaQuery';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { passwordRules } from '../../../services/dataValidator';
import { ROUTE_PATHS } from '../../../CONSTANTS/paths';
import useAuthStyles from '../../../styles/authStyles';

const validators: LoginValidators = {
  email: emailValidator,
  password: passwordValidator,
};

const theme = createTheme({
  palette: {
    primary: {
      light: '#737ae0',
      main: '#7047ef',
      dark: '#5238c9',
      contrastText: '#fff',
    },
  },
});

const LoginPage: React.FC = (): ReactElement => {
  const navigate = useNavigate();
  const [state, setState] = useState<State>({
    formValues: {
      password: '',
      email: '',
    },
    showPassword: false,
    focused: null,
    waitingForLogin: false,
    errors: [],
  });

  const classes = useAuthStyles();

  const handleChange = useCallback(
    (prop: keyof FormValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setState((prevState) => ({
        ...prevState,
        formValues: { ...prevState.formValues, [prop]: event.target.value },
      }));
    },
    [],
  );

  const handleFocus = useCallback(
    (prop: keyof FormValues) => () => {
      setState((prevState) => ({
        ...prevState,
        focused: prop,
        errors: prevState.errors.filter((error) => error.key !== prop),
      }));
    },
    [],
  );

  const handleBlur = useCallback(
    (prop: keyof FormValues) => () => {
      setState((prevState) => ({
        ...prevState,
        focused: prop === prevState.focused ? null : prevState.focused,
      }));
    },
    [],
  );

  const handleClickShowPassword = () => {
    setState((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const hasError = useCallback(
    (key: keyof FormValues) => !!state.errors.find((error) => error.key === key),
    [state.errors],
  );

  const getHelperText = useCallback(
    (key: keyof FormValues) => state.errors.find((error) => error.key === key)?.message ?? ' ',
    [state.errors],
  );

  const generatePasswordRulesListItems = useCallback(
    () =>
      Object.values(passwordRules).map((rule, index) => {
        const checked = rule.test(state.formValues.password);
        return (
          <ListItem key={index}>
            <ListItemIcon
              sx={{
                pr: 1,
                minWidth: '14px',
                color: checked ? 'success.main' : 'error.main',
              }}>
              {checked ? (
                <RadioButtonCheckedIcon
                  sx={{
                    width: '14px',
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  sx={{
                    width: '14px',
                  }}
                />
              )}
            </ListItemIcon>
            <ListItemText
              sx={{
                my: 0,
                color: checked ? 'success.main' : 'error.main',
              }}
              primary={rule.label}
            />
          </ListItem>
        );
      }),
    [state.formValues.password],
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validationResponses = await Promise.allSettled(
      Object.keys(validators).map((key) =>
        validators[key as LoginValidatorKey].validate(state.formValues[key as keyof FormValues]),
      ),
    );
    const errors = validationResponses.reduce((acc: ValidationErrors[], response, index) => {
      if (response.status === 'rejected') {
        acc.push({
          key: Object.keys(validators)[index] as LoginValidatorKey,
          message: response.reason[0].message,
        });
      }
      return acc;
    }, []);

    if (errors.length > 0) {
      setState({
        ...state,
        errors,
      });
      return;
    }

    await api.auth.signIn({
      email: state.formValues.email,
      password: state.formValues.password,
    });
    navigate(ROUTE_PATHS.ACCOUNTS);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        component="main">
        <Container maxWidth="xs" className={classes.auth}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pb: 6,
            }}>
            <Avatar sx={{ m: 1 }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <FormControl
                variant="outlined"
                fullWidth
                sx={{ mt: 2, mb: 0 }}
                error={hasError('email')}>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <OutlinedInput
                  id="email"
                  name="email"
                  type="text"
                  value={state.formValues.email}
                  onChange={handleChange('email')}
                  onFocus={handleFocus('email')}
                  onBlur={handleBlur('email')}
                  label="Email Address"
                  autoFocus
                />
                <FormHelperText error>{getHelperText('email')}</FormHelperText>
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                sx={{ mt: 2, mb: 0 }}
                error={hasError('password')}>
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  type={state.showPassword ? 'text' : 'password'}
                  value={state.formValues.password}
                  onChange={handleChange('password')}
                  onFocus={handleFocus('password')}
                  onBlur={handleBlur('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                <FormHelperText error>{getHelperText('password')}</FormHelperText>
                <Box
                  style={{
                    position: 'absolute',
                    right: betweenBreakpoints({ minWidth: WINDOW_BREAKPOINTS.LG }) ? 0 : 'auto',
                    top: 0,
                    transform: betweenBreakpoints({ minWidth: WINDOW_BREAKPOINTS.LG })
                      ? 'translate(calc(100% + 12px), 0)'
                      : 'translate(0, calc(-100% - 12px))',
                    zIndex: 5000,
                    pointerEvents: 'none',
                  }}>
                  <Grow
                    in={state.focused === 'password'}
                    style={{
                      transformOrigin: '0 0 0',
                    }}
                    timeout={state.focused === 'password' ? 200 : 400}>
                    <Box
                      sx={{
                        backgroundColor: '#f6f8ff',
                        borderRadius: '4px',
                        boxShadow:
                          'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                      }}>
                      <List
                        subheader={
                          <ListSubheader
                            component="div"
                            id="list-subheader"
                            sx={{
                              backgroundColor: '#f6f8ff',
                              borderRadius: '4px',
                              fontSize: 16,
                            }}>
                            Password requirements:
                          </ListSubheader>
                        }
                        dense>
                        {generatePasswordRulesListItems()}
                      </List>
                    </Box>
                  </Grow>
                </Box>
              </FormControl>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  flexWrap: 'wrap',
                  gap: '20px 10px',
                  mb: 2,
                }}>
                <Link to="/accounts/sign-up" style={{ textDecoration: 'none' }}>
                  <Button size="large" variant="contained" color="primary">
                    Create Account
                  </Button>
                </Link>
                <LoadingButton
                  loading={state.waitingForLogin}
                  loadingPosition="end"
                  endIcon={<ArrowRightIcon />}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary">
                  Sign In
                </LoadingButton>
              </Box>
            </Box>
            <Link to={ROUTE_PATHS.SEND_CODE_FOR_RESET}>Forget your password?</Link>
          </Box>
        </Container>
      </Grid>
    </ThemeProvider>
  );
};

export default LoginPage;
