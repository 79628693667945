export const AUTH_PATHS = ['signin', 'email/activate', 'signin/refresh'];

export const PATHS = {
  SIGN_IN: 'signin',
  SIGNIN_REFRESH: 'signin/refresh',
  EMAIL_ACTIVATE: 'email/activate',
  SIGN_OUT: 'me/signout',
  FETCH_ME: 'me',
  PASSWORD_RESET: 'password/change/code',
};

export const ROUTE_PATHS = {
  EMAIl_ACTIVATE: '/accounts/email-confirm/activate',
  SIGN_IN: '/accounts/sign-in',
  SIGN_UP: '/accounts/sign-up',
  ACCOUNTS: '/accounts',
  PASSWORD_RESET: '/accounts/password-reset',
  SEND_CODE_FOR_RESET: '/accounts/send-reset-code',
};
