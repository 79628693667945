import ApiError, { ApiErrorProps } from './classes/ApiError';
import { ApiResponse } from './types';

export const getUrl = (path: string) =>
  `${process.env.REACT_APP_IS_LOCALHOST ? `${process.env.REACT_APP_CORS_ANIWHERE}/` : ''}${
    new URL(path, process.env.REACT_APP_API_URL).href
  }`;

export const getError = ({ status, message, title }: ApiErrorProps): ApiError =>
  new ApiError({
    status,
    message,
    title,
  });

export const getUnknownError = (): ApiError =>
  getError({
    status: null,
    message: 'We already know about this problem and will solve it soon! Please try again later.',
    title: 'Some Error Occurred',
  });

export const getServerError = (status: number): ApiError =>
  getError({
    status,
    message: 'We already know about this problem and will solve it soon! Please try again later.',
    title: '500 — Server Error',
  });

export const getResponseResult = async (
  fetchPromise: Promise<Response>,
): Promise<ApiResponse<any>> => {
  const response = await fetchPromise;
  if (response.status === 204) return { data: null, status: response.status };
  if (response.ok) {
    try {
      const json = await response.json();
      return {
        data: json,
        status: response.status,
      };
    } catch (error: any) {
      throw getUnknownError();
    }
  } else {
    const json = await response.json();
    throw getError({
      status: response.status,
      message: json.message,
      title: 'Error',
    });
  }
};
