import { toast } from 'react-toastify';

export const toastGenerator = (text: string) => {
  return toast(text, {
    autoClose: 3000,
    type: 'success',
    hideProgressBar: true,
    closeOnClick: true,
    theme: 'colored',
  });
};
