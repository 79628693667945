import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';
import api from '../../api';
import { useNavigate } from 'react-router-dom';
import { toastGenerator } from '../../utils/toast';
import { ROUTE_PATHS } from '../../CONSTANTS/paths';

const Accounts: React.FC = (): ReactElement => {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await api.account.signOut();
    navigate(ROUTE_PATHS.SIGN_IN);
    toastGenerator('You successfully logged out');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pb: 6,
      }}>
      <Typography component="h1" variant="h5" sx={{ mt: 4 }}>
        Welcome!
      </Typography>
      <button onClick={() => handleSignOut()}>Sign Out</button>
    </Box>
  );
};

export default Accounts;
