import * as ls from 'local-storage'
import { Token } from './types'

export default {
  getAccess: () : Token => ls.get('accessToken'),
  hasAccess: () : boolean => !!ls.get('accessToken'),
  setAccess: (access: string) : Boolean => ls.set('accessToken', access),
  removeAccess: () : void => ls.remove('accessToken'),
  getRefresh: () : Token => ls.get('refreshToken'),
  hasRefresh: () : boolean => !!ls.get('refreshToken'),
  setRefresh: (refresh: string) : Boolean => ls.set('refreshToken', refresh),
  removeRefresh: () : void => ls.remove('refreshToken'),
}
