import * as punycode from 'punycode';
import rules from './rules';
import ERROR_MESSAGES from './ERROR_MESSAGES';
import {
  ValidationError,
  IDataValidator,
  IDataValidation,
  ValidationResponse,
  DataValidationMethod,
} from './types';

export const passwordRules = {
  HAS_LOWERCASE_CHARACTERS: {
    label: 'Latin characters only',
    test: (value: string) => rules.NOT_EMPTY(value) && rules.PASSWORD.HAS.LATIN_CHARACTERS(value),
  },
  CONTAIN_LOWER_UPPER_CASE_CHARACTERS: {
    label: 'Small and capital letters',
    test: (value: string) =>
      rules.NOT_EMPTY(value) &&
      rules.PASSWORD.CONTAIN.LOWERCASE_CHARACTERS(value) &&
      rules.PASSWORD.CONTAIN.UPPERCASE_CHARACTERS(value),
  },
  CONTAIN_DIGITS: {
    label: 'Minimum 1 digit',
    test: (value: string) => rules.NOT_EMPTY(value) && rules.PASSWORD.CONTAIN.DIGITS(value),
  },
  HAS_VALID_CHARACTERS: {
    label: 'Allowed symbols: _ - ! @ # $ % () * &',
    test: (value: string) => rules.NOT_EMPTY(value) && rules.PASSWORD.HAS.VALID_CHARACTERS(value),
  },
  HAS_LENGTH: {
    label: 'From 4 to 50 symbols',
    test: (value: string) => rules.NOT_EMPTY(value) && rules.PASSWORD.HAS.LENGTH(value),
  },
};

function staticImplements<T>() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (constructor: T) => {};
}

@staticImplements<IDataValidation>()
export default class DataValidator implements IDataValidator {
  validationSet: DataValidationMethod[] = [];

  constructor(methods: DataValidationMethod[]) {
    this.pushMethods(methods);
  }

  pushMethods(methods: DataValidationMethod[]) {
    this.validationSet.push(...methods);
  }

  validate(value: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const errors: ValidationError[] = [];

      this.validationSet.forEach((validationMethod) => {
        const result = validationMethod(value);
        if (!result.success && result.error) {
          errors.push(result.error);
        }
      });

      if (errors.length > 0) {
        reject(errors);
      } else {
        resolve(true);
      }
    });
  }

  static isNotEmpty(value: string): ValidationResponse {
    const defaultError = {
      message: ERROR_MESSAGES.NOT_EMPTY(),
    };

    const success = rules.NOT_EMPTY(value);
    return {
      success,
      error: success ? undefined : defaultError,
    };
  }

  static isEmail(value: string): ValidationResponse {
    const defaultError = {
      message: ERROR_MESSAGES.EMAIL(),
    };

    const success = rules.EMAIL(punycode.toUnicode(value));
    return {
      success,
      error: success ? undefined : defaultError,
    };
  }

  static isName(value: string): ValidationResponse {
    const defaultError = {
      message: ERROR_MESSAGES.NAME(),
    };

    const success = rules.NAME(value);

    return {
      success,
      error: success ? undefined : defaultError,
    };
  }

  static isCode(value: string): ValidationResponse {
    const defaultError = {
      message: ERROR_MESSAGES.CODE(),
    };

    const success = rules.CODE(value);
    return {
      success,
      error: success ? undefined : defaultError,
    };
  }

  static isPhone(value: string): ValidationResponse {
    const defaultError = {
      message: ERROR_MESSAGES.PHONE(),
    };

    const success = rules.PHONE(value);
    return {
      success,
      error: success ? undefined : defaultError,
    };
  }

  static isPassword(value: string): ValidationResponse {
    const defaultError = {
      message: ERROR_MESSAGES.PASSWORD(),
    };

    const success = [
      rules.PASSWORD.CONTAIN.LOWERCASE_CHARACTERS,
      rules.PASSWORD.CONTAIN.UPPERCASE_CHARACTERS,
      rules.PASSWORD.CONTAIN.DIGITS,
      rules.PASSWORD.HAS.LATIN_CHARACTERS,
      rules.PASSWORD.HAS.VALID_CHARACTERS,
      rules.PASSWORD.HAS.LENGTH,
    ].every((rule) => rule(value));
    return {
      success,
      error: success ? undefined : defaultError,
    };
  }
}

export const emailValidator = new DataValidator([DataValidator.isNotEmpty, DataValidator.isEmail]);

export const fistNameValidator = new DataValidator([
  DataValidator.isNotEmpty,
  DataValidator.isName,
]);

export const lastNameValidator = new DataValidator([
  DataValidator.isNotEmpty,
  DataValidator.isName,
]);

export const passwordValidator = new DataValidator([
  DataValidator.isNotEmpty,
  DataValidator.isPassword,
]);

export const invitationCodeValidator = new DataValidator([DataValidator.isCode]);
