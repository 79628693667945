import jwtToken from '../services/authProvider/jwtToken'
import { DefaultHeaders } from './types'

const permanentHeaders = {
  'Api-Key': process.env.REACT_APP_API_KEY!,
  'Api-Version': process.env.REACT_APP_API_VERSION!,
  'App-Name': process.env.REACT_APP_APP_NAME!,
  'App-Version': process.env.REACT_APP_VERSION!,
  Platform: process.env.REACT_APP_API_PLATFORM!,
  'Content-Type': 'application/json',
  'Accept-Charset': 'UTF-8',
}

export const getHeaders = () => {
  const token = jwtToken.getAccess()
  const headers: DefaultHeaders = {
    'Accept-Language': process.env.REACT_APP_API_FALLBACK_LOCALE!,
    ...permanentHeaders,
  }
  if (token) {
    headers.Authorization = token
  }
  return headers
}
