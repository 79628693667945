import { combineReducers } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import accountReducer from './slices/accountSlice';
import * as createHistory from 'history';

export const history = createHistory.createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  router: connectRouter(history),
  account: accountReducer,
});

const store = configureStore({ reducer, middleware: [sagaMiddleware, routerMiddleware(history)] });

// Run the saga
// sagaMiddleware.run(rootSaga);

export default store;
