import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactElement } from 'react';
import api from '../../../api';
import { Button } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../CONSTANTS/paths';
import useAuthStyles from '../../../styles/authStyles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#737ae0',
      main: '#7047ef',
      dark: '#5238c9',
      contrastText: '#fff',
    },
  },
});

const AccountActivation: React.FC = (): ReactElement => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, _] = useSearchParams();
  const activationCode = params.get('code');

  const handleActivate = async () => {
    if (!activationCode) return navigate(ROUTE_PATHS.SIGN_IN);
    await api.auth.activateAccount({ code: activationCode });
    navigate(ROUTE_PATHS.ACCOUNTS);
  };
  const classes = useAuthStyles();

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        component="main">
        <Container maxWidth="xs" className={classes.auth}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pb: 6,
            }}>
            <Typography component="h1" variant="h5" sx={{ mt: 4 }}>
              Account Activation
            </Typography>
            <Button
              size="large"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={() => handleActivate()}>
              Activate
            </Button>
          </Box>
        </Container>
      </Grid>
    </ThemeProvider>
  );
};

export default AccountActivation;
