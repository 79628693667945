import { makeStyles } from '@mui/styles';

const useAuthStyles = makeStyles(() => ({
  auth: {
    minWidth: 300,
    width: '25%',
    margin: '250px auto 0',
    border: '1px solid #cccccc',
    borderRadius: 8,
  },
}));

export default useAuthStyles;
