import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AccountState } from './types'

// Define the initial state using that type
const initialState: AccountState = {}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount: (state: AccountState, action: PayloadAction<AccountState>) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key as keyof AccountState] = value
      })
    },
    clearAccount: (state: AccountState) => {
      Object.keys(state).forEach((key) => {
        state[key as keyof AccountState] = undefined
      })
    },
  },
})

export const { setAccount, clearAccount } = accountSlice.actions

export default accountSlice.reducer
