import jwtToken from '../authProvider/jwtToken';
import api from '../../api';
import { GetMeResponseData } from '../../api/modules/account/types';
import { ApiResponse } from '../../api/types';
import store from './../../store/store';
import { setAccount, clearAccount } from '../../store/slices/accountSlice';
import { AccountState } from '../../store/slices/accountSlice/types';

const accountManager = {
  async fetchMe(): Promise<ApiResponse<GetMeResponseData>> {
    if (jwtToken.hasAccess()) {
      const response = await api.account.fetchMe();
      store.dispatch(setAccount(response.data));
      return response;
    }
    return Promise.reject();
  },
  getMe(): AccountState {
    return store.getState().account;
  },
  clearData(): void {
    store.dispatch(clearAccount());
  },
  setData(data: GetMeResponseData): void {
    store.dispatch(setAccount(data));
  },
};

export default accountManager;
