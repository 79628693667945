import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactElement, useCallback, useState } from 'react';
import api from '../../../../api';
import { emailValidator } from '../../../../services/dataValidator';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import {
  State,
  FormValues,
  ByEmailValidatorKey,
  ByEmailValidators,
  ValidationErrors,
} from './types';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../../CONSTANTS/paths';
import { toastGenerator } from '../../../../utils/toast';
import useAuthStyles from '../../../../styles/authStyles';

const validators: ByEmailValidators = {
  email: emailValidator,
};

const theme = createTheme({
  palette: {
    primary: {
      light: '#737ae0',
      main: '#7047ef',
      dark: '#5238c9',
      contrastText: '#fff',
    },
  },
});

const ByEmail: React.FC = (): ReactElement => {
  const [isSent, setIsSent] = useState<boolean>(false);
  const [state, setState] = useState<State>({
    formValues: {
      email: '',
    },
    focused: null,
    errors: [],
  });

  const classes = useAuthStyles();

  const handleChange = useCallback(
    (prop: keyof FormValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setState((prevState) => ({
        ...prevState,
        formValues: { ...prevState.formValues, [prop]: event.target.value },
      }));
    },
    [],
  );

  const handleFocus = useCallback(
    (prop: keyof FormValues) => () => {
      setState((prevState) => ({
        ...prevState,
        focused: prop,
        errors: prevState.errors.filter((error) => error.key !== prop),
      }));
    },
    [],
  );

  const handleBlur = useCallback(
    (prop: keyof FormValues) => () => {
      setState((prevState) => ({
        ...prevState,
        focused: prop === prevState.focused ? null : prevState.focused,
      }));
    },
    [],
  );

  const hasError = useCallback(
    (key: keyof FormValues) => !!state.errors.find((error) => error.key === key),
    [state.errors],
  );

  const getHelperText = useCallback(
    (key: keyof FormValues) => state.errors.find((error) => error.key === key)?.message ?? ' ',
    [state.errors],
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validationResponses = await Promise.allSettled(
      Object.keys(validators).map((key) =>
        validators[key as ByEmailValidatorKey].validate(state.formValues[key as keyof FormValues]),
      ),
    );
    const errors = validationResponses.reduce((acc: ValidationErrors[], response, index) => {
      if (response.status === 'rejected') {
        acc.push({
          key: Object.keys(validators)[index] as ByEmailValidatorKey,
          message: response.reason[0].message,
        });
      }
      return acc;
    }, []);

    if (errors.length > 0) {
      setState({
        ...state,
        errors,
      });
      return;
    }

    await api.auth.sendResetCode({
      email: state.formValues.email,
    });
    setIsSent(true);
    toastGenerator('Code was sent!');
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        component="main">
        <Container maxWidth="xs" className={classes.auth}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              pb: 4,
              mt: 4,
            }}>
            {isSent ? (
              <Typography component="h1" variant="h5">
                Your activation link was sent to your email
              </Typography>
            ) : (
              <>
                <Typography component="h1" variant="h5">
                  Send reset link to email
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 2, mb: 0 }}
                    error={hasError('email')}>
                    <InputLabel htmlFor="email">Email Address</InputLabel>
                    <OutlinedInput
                      id="email"
                      name="email"
                      type="text"
                      value={state.formValues.email}
                      onChange={handleChange('email')}
                      onFocus={handleFocus('email')}
                      onBlur={handleBlur('email')}
                      label="Email Address"
                      autoFocus
                    />
                    <FormHelperText error>{getHelperText('email')}</FormHelperText>
                  </FormControl>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      flexWrap: 'wrap',
                      gap: '20px 10px',
                      mb: 2,
                    }}>
                    <Link to={ROUTE_PATHS.SIGN_IN} style={{ textDecoration: 'none' }}>
                      <Button
                        type="submit"
                        size="large"
                        variant="contained"
                        color="primary"
                        startIcon={<ArrowLeftIcon />}>
                        Go Back
                      </Button>
                    </Link>
                    <Button type="submit" size="large" variant="contained" color="primary">
                      Send Code
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Container>
      </Grid>
    </ThemeProvider>
  );
};

export default ByEmail;
