import rest from '../../api';
import { GetMeResponseData } from './types';
import { ApiResponse } from '../../types';
import { PATHS } from '../../../CONSTANTS/paths';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchMe: async (): Promise<ApiResponse<GetMeResponseData>> => rest.get(PATHS.FETCH_ME),
  signOut: async (): Promise<ApiResponse<Object[]>> => rest.post(PATHS.SIGN_OUT),
};
