import React, { useEffect } from 'react';
import './App.scss';
import LoginPage from './components/Auth/LoginPage/LoginPage';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AccountActivation from './components/Auth/AccountActivation/AccountActivation';
import jwtToken from './services/authProvider/jwtToken';
import RegistrationPage from './components/Auth/RegistrationPage/RegistrationPage';
import Accounts from './components/Accounts/Accounts';
import PasswordReset from './components/Auth/PasswordReset/PasswordReset';
import ByEmail from './components/Auth/PasswordReset/ByEmail/ByEmail';
import { ROUTE_PATHS } from './CONSTANTS/paths';

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const hasToken = jwtToken.hasAccess();
  useEffect(() => {
    // TODO: Not the final path for Main route, we have to change the route depending on the final form of project url
    if (hasToken) {
      navigate(ROUTE_PATHS.ACCOUNTS);
    }
    if (!hasToken && location.pathname === ROUTE_PATHS.ACCOUNTS) navigate(ROUTE_PATHS.SIGN_IN);
  }, [hasToken, navigate, location.pathname]);

  return (
    <>
      <div className="app">
        <Routes>
          <Route path={ROUTE_PATHS.EMAIl_ACTIVATE} element={<AccountActivation />} />
          <Route path={ROUTE_PATHS.SIGN_IN} element={<LoginPage />} />
          <Route path={'/'} element={<LoginPage />} />
          <Route path={ROUTE_PATHS.SIGN_UP} element={<RegistrationPage />} />
          <Route path={ROUTE_PATHS.PASSWORD_RESET} element={<PasswordReset />} />
          <Route path={ROUTE_PATHS.SEND_CODE_FOR_RESET} element={<ByEmail />} />
          {
            // TODO: Not the final path for Main route, we have to change the route depending on the final form of project url
            hasToken && <Route path={ROUTE_PATHS.ACCOUNTS} element={<Accounts />} />
          }
        </Routes>
      </div>
    </>
  );
}

export default App;
