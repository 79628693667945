import { toast } from 'react-toastify';
import { getResponseResult, getServerError, getUnknownError, getUrl } from './utils';
import { ApiResponse } from './types';
import { getHeaders } from './headers';
import ApiError from './classes/ApiError';
import auth from './modules/auth';
import jwtToken from '../services/authProvider/jwtToken';
import api from './index';
import accountManager from '../services/accountManager';
import { AUTH_PATHS, PATHS } from '../CONSTANTS/paths';

const request = async (
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  path: string,
  params: { [key: string]: any } = {},
): Promise<ApiResponse<any>> => {
  const url = getUrl(path);
  try {
    const fetchParams: RequestInit = {
      method,
      headers: new Headers(getHeaders()),
    };
    if (Object.values(params).length > 0) {
      fetchParams.body = JSON.stringify(params);
    }

    if (path === PATHS.SIGN_OUT) {
      jwtToken.removeAccess();
      jwtToken.removeRefresh();
      accountManager.clearData();
    }

    const response = await getResponseResult(fetch(url, fetchParams));

    if (AUTH_PATHS.includes(path)) {
      jwtToken.setAccess(response.data.access || response.data.jwt.access);
      jwtToken.setRefresh(response.data.refresh || response.data.jwt.refresh);
      const accountResponse = await api.account.fetchMe();
      accountManager.setData(accountResponse.data);
    }

    return response;
  } catch (error: ApiError | Error | unknown) {
    let preparedError!: ApiError;
    if (!(error instanceof ApiError) || !error.status) {
      preparedError = getUnknownError();
    } else if ([401].includes(error.status) && jwtToken.hasRefresh()) {
      try {
        const refresh = jwtToken.getRefresh();
        jwtToken.removeAccess();
        jwtToken.removeRefresh();
        const response = await auth.refresh({
          token: refresh as string,
        });
        jwtToken.setAccess(response.data.access);
        jwtToken.setRefresh(response.data.refresh);
      } catch {}
      if (jwtToken.hasRefresh()) {
        return await request(method, path, params);
      }
    } else {
      const statusCodeGroup = `${Math.floor(error.status / 100)}xx`;
      switch (statusCodeGroup) {
        case '4xx':
          preparedError = error;
          break;
        case '5xx':
          preparedError = getServerError(error.status);
          break;
        default:
          preparedError = getUnknownError();
      }
    }
    toast(preparedError.message, {
      autoClose: 3000,
      type: 'error',
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      theme: 'colored',
    });
    throw preparedError;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: async (path: string, params: { [key: string]: any } = {}) => request('GET', path, params),
  post: async (path: string, params: { [key: string]: any } = {}) => request('POST', path, params),
  put: async (path: string, params: { [key: string]: any } = {}) => request('PUT', path, params),
};
